<template>
    <div class="wrapper-page">
      <div class="section">
        <div class="frame">
          <div class="frame9">
            <span class="judulkonten">DAFTAR PEJABAT</span>
          </div>
          <div class="framepejabat">
            <div class="section-detail-pejabat">
              <a target="_blank" class="isikonten" style="padding: 5px 10px; background-color: #ffbb00; color: #ffffff; text-decoration: none; border-radius: 10px;" href="https://drive.google.com/drive/folders/1dAf3uB9xVFCBzJ90wngkMm1feeaqqqjP">
                <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #ffffff;" />
                Lihat Detail Info Pejabat
              </a>
            </div>
            <div class="daftarpejabat">
              <div class="isidaftarpejabat" :id="index" v-for="(item, index) in daftarPejabat" :key="index">
                <div class="fotopejabat">
                  <img :src="baseIMGURL + item.foto" style="width: 15vw;"/>
                </div>
                <div class="inforpejabat">
                  <span class="isikonten" style="font-size: 1rem; font-weight: 1000; text-align: center;">{{ item.nama }}</span>
                  <span class="isikonten" style="font-size: 0.8rem; font-weight: 1000; text-align: center;">{{ item.jabatan }}</span>
                  <span v-if="item.kunker === '00'" class="isikonten" style="font-size: 0.8rem; font-weight: 1000; text-align: center;">( Pejabat Eselon I )</span>
                  <span v-else class="isikonten" style="font-size: 15px; font-weight: 1000; text-align: center;">( Pejabat Eselon II )</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'DaftarPejabatWeb',
    components: {},
    data () {
      return {
        baseIMGURL: 'https://www.kemendagri.go.id/api/file-media-services/',
        daftarPejabat: []
      }
    },
    mounted () {
      axios.get('https://www.kemendagri.go.id/gateway/api/pejabateselon2?nomor=03')
      .then((response)=>{
        // handle success
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.daftarPejabat.push(response.data.data[i]);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
    },
    methods: {
    }
  }
  </script>