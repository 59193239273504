<template>
    <div class="wrapper-page">
      <div class="section" style="height: 450px;">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">RENCANA KERJA</span>
            </div>
            <div class="frame10">
                <div class="rencanakerja">
                    <table class="isikonten" style="gap: 10px; display: flex; flex-direction: column;">
                        <thead style="border-bottom: 3px solid #002c5573;">
                            <tr>
                                <template v-for="(item, index) in headers" v-bind:key="index">
                                    <th :style="item.width">{{ item.text }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody style="display: flex; gap: 5px; flex-direction: column; justify-content: center; align-items: center;">
                            <template v-for="(itemm, indexx) in items" v-bind:key="indexx">
                                <tr style="font-size: 13px; text-align: center; border-bottom: 1px solid #002c5573;">
                                    <td style="padding: 5px 5px; width: 375px; font-size: 16px; text-align: left;">{{ itemm.judul_rencana_kerja }}</td>
                                    <td style="padding: 5px 5px; width: 100px; font-size: 13px; text-align: center;">{{ itemm.tahun }}</td>
                                    <td style="padding: 5px 5px; width: 150px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.dokumen" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                                            Unduh Dokumen
                                        </a>
                                    </td>
                                    <td style="padding: 5px 5px; width: 250px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.dukungan" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                                            Unduh Dokumen Dukungan
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'RencanaKerjaAnggaranWeb',
    components: {
    },
    data () {
      return {
        headers:[
          { text: "Judul Rencana Kerja", value: "judul_rencana_kerja", width: "width: 375px; padding: 5px 5px; text-align: center;" },
          { text: "Tahun", value: "tahun", sortable: true, width: "width: 100px; padding: 5px 5px; text-align: center;"  },
          { text: "Dokumen", value: "dookumen", sortable: true, width: "width: 150px; padding: 5px 5px; text-align: center;"  },
          { text: "Dukungan", value: "dukungan", sortable: true, width: "width: 250px; padding: 5px 5px; text-align: center;"  }
        ],
        items: []
      }
    },
    mounted () {
        const response = {
            data: {
                data: [
                    {
                        judul_rencana_kerja: "Laporan Kinerja Ditjen Politik dan Pemerintahan Umum",
                        tahun: '2020-2024',
                        dokumen: "https://polpum.kemendagri.go.id/wp-content/uploads/2019/07/001-Renstra-Ditjen-Polpum-1.pdf",
                        dukungan: "https://polpum.kemendagri.go.id/wp-content/uploads/2019/07/renja-2023-polpum-1.pdf",
                    },
                ]
            }
        }
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.items.push(response.data.data[i]);
        }
    },
    methods: {
    }
  }
  </script>