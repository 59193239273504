
import 'mutationobserver-shim'
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue3Lottie from "vue3-lottie";
import "./assets/css/main.css";
import "@fontsource/open-sans";
import { library } from '@fortawesome/fontawesome-svg-core'

/* add some free styles */
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import {fas} from "@fortawesome/free-solid-svg-icons";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faTwitter, faUserSecret, faFacebook, faInstagram, faYoutube, fas)

createApp(App).use(store).use(router).use(Vue3Lottie).component('font-awesome-icon', FontAwesomeIcon).mount("#app");
