import { createRouter, createWebHashHistory } from 'vue-router'
import BerandaWeb from '../views/Beranda.vue'
import PageWeb from '../views/Page.vue'
import PageRedirectWeb from '../views/PageRedirect.vue'
import DaftarPejabatWeb from '../views/DaftarPejabat.vue'
import StrukturWeb from '../views/Struktur.vue'
import LaporanKinerjaWeb from '../views/LaporanKinerja.vue'
import PrestasiPenghargaanWeb from '../views/PrestasiPenghargaan.vue'
import RencanaKerjaAnggaranWeb from '../views/RencanaKerja.vue'
import InformasiLayananWeb from '../views/InformasiLayanan.vue'
import DaftarKegiatanWeb from '../views/DaftarKegiatan.vue'
import ForumGerakanWeb from '../views/ForumGerakan.vue'
import DaftarOrmsSKTWeb from '../views/DaftarOrmas.vue'

const routes = [
  {
    path: '/',
    name: 'beranda',
    component: BerandaWeb
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:id',
    name: 'page',
    component: PageWeb
  },
  {
    path: '/page/:id',
    name: 'pageredirect',
    component: PageRedirectWeb
  },
  {
    path: '/daftar-pejabat',
    name: 'daftar-pejabat',
    component: DaftarPejabatWeb
  },
  {
    path: '/struktur',
    name: 'struktur',
    component: StrukturWeb
  },
  {
    path: '/laporan-kinerja',
    name: 'laporan-kinerja',
    component: LaporanKinerjaWeb
  },
  {
    path: '/rencana-kerja-anggaran',
    name: 'rencana-kerja-anggaran',
    component: RencanaKerjaAnggaranWeb
  },
  {
    path: '/prestasi-penghargaan',
    name: 'prestasi-penghargaan',
    component: PrestasiPenghargaanWeb
  },
  {
    path: '/informasi-layanan',
    name: 'informasi-layanan',
    component: InformasiLayananWeb
  },
  {
    path: '/daftar-kegiatan',
    name: 'daftar-kegiatan',
    component: DaftarKegiatanWeb
  },
  {
    path: '/forum-gerakan',
    name: 'forum-gerakan',
    component: ForumGerakanWeb
  },
  {
    path: '/daftar-ormas-skt',
    name: 'daftar-ormas-skt',
    component: DaftarOrmsSKTWeb
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
