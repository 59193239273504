<template>
    <div class="wrapper-page">
      <div class="section" style="height: 450px;">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">INFORMASI LAYANAN</span>
            </div>
            <div class="frame10">
                <div class="rencanakerja">
                    <table class="isikonten" style="gap: 10px; display: flex; flex-direction: column;">
                        <thead style="border-bottom: 3px solid #002c5573;">
                            <tr>
                                <template v-for="(item, index) in headers" v-bind:key="index">
                                    <th :style="item.width">{{ item.text }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody style="display: flex; gap: 5px; flex-direction: column; justify-content: center; align-items: center;">
                            <template v-for="(itemm, indexx) in items" v-bind:key="indexx">
                                <tr style="border-bottom: 1px solid #002c5573;">
                                    <td style="padding: 5px 5px; width: 725px; font-size: 16px; text-align: left;">{{ itemm.tentang }}</td>
                                    <td style="padding: 5px 5px; width: 150px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.dokumen" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                                            Unduh Dokumen
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'RencanaKerjaAnggaranWeb',
    components: {
    },
    data () {
      return {
        headers:[
          { text: "Tentang", value: "tentang", width: "width: 725px; padding: 5px 5px; text-align: center;" },
          { text: "Dokumen", value: "dokumen", sortable: true, width: "width: 150px; padding: 5px 5px; text-align: center;"  }
        ],
        items: []
      }
    },
    mounted () {
        const response = {
            data: {
                data: [
                    {
                        tentang: "SOP Pelayanan SPP Orang Asing dan Lembaga Asing",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/SOP_P_SPP_REVISI_.pdf",
                    },
                    {
                        tentang: "Mekanisme Pelayanan Surat Pemberitahuan Penelitian (SPP) Orang Asing Dan Lembaga Asing",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Mekanisme_SPP_Orang_Asing_dan_Lembaga_Asing1.pdf",
                    },
                    {
                        tentang: "SOP Rekomendasi Penelitian Dalam Negeri",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/SOP_PENERBITAN_SPP_TTD_DIR_ok.pdf",
                    },
                    {
                        tentang: "Pedoman Penerbitan Rekomendasi Penelitian",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Permen_No._64_Tahun_2011__2.pdf",
                    },
                    {
                        tentang: "Mekanisme Pelayanan Surat Keterangan Terdaftar (SKT) Ormas/LSM/LNL",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Mekanisme_Ormas.pdf",
                    },
                    {
                        tentang: "Formulir Surat Keterangan Terdaftar Ormas",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Peryaratan_dan_Formulir_SKT.pdf",
                    },
                ]
            }
        }
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.items.push(response.data.data[i]);
        }
    },
    methods: {
    }
  }
  </script>