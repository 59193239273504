<template>
    <div class="wrapper-page">
      <div class="section" style="height: 450px;">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">DAFTAR KEGIATAN</span>
            </div>
            <div style="width: 100%; display: flex; flex-direction: row-reverse; align-items: center; justify-content: space-evenly;">
                <span class="">Filter Tahun : 
                    <select>
                        <option>2024</option>
                        <option>2023</option>
                        <option>2022</option>
                    </select>
                </span>
                <span class="">Cari Kegiatan : 
                    <input style="width: 42vw; padding: 0.5rem; border-radius: 12px; border: solid 0.8px grey;"/>
                </span>
            </div>
            <div class="frame10">
                <div class="rencanakerja">
                    <table class="isikonten" style="gap: 10px; display: flex; flex-direction: column;">
                        <thead style="border-bottom: 3px solid #002c5573;">
                            <tr>
                                <template v-for="(item, index) in headers" v-bind:key="index">
                                    <th :style="item.width">{{ item.text }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody style="display: flex; gap: 5px; flex-direction: column; justify-content: center; align-items: center;">
                            <template v-for="(itemm, indexx) in items" v-bind:key="indexx">
                                <tr style="border-bottom: 1px solid #002c5573;">
                                    <td style="padding: 5px 5px; width: 300px; font-size: 16px; text-align: left;">{{ itemm.tentang }}</td>
                                    <td style="padding: 5px 5px; width: 75px; font-size: 16px; text-align: center;">{{ itemm.tahun }}</td>
                                    <td style="padding: 5px 5px; width: 250px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.link" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                                            Pendaftaran
                                        </a>
                                    </td>
                                    <td style="padding: 5px 5px; width: 250px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.dokumen" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                                            Materi & Dokumentasi
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'RencanaKerjaAnggaranWeb',
    components: {
    },
    data () {
      return {
        headers:[
          { text: "Tentang", value: "tentang", width: "width: 300px; padding: 5px 5px; text-align: center;" },
          { text: "Tahun", value: "tahun", sortable: true, width: "width: 75px; padding: 5px 5px; text-align: center;"  },
          { text: "Tautan Pendaftaran", value: "link", sortable: true, width: "width: 250px; padding: 5px 5px; text-align: center;"  },
          { text: "Materi & Dokumentasi Kegiatan", value: "dokumen", sortable: true, width: "width: 250px; padding: 5px 5px; text-align: center;"  }
        ],
        items: []
      }
    },
    mounted () {
        const response = {
            data: {
                data: [
                    {
                        tentang: "RAPAT KOORDINASI NASIONAL",
                        tahun: "2024",
                        link: "https://forms.gle/v6Vs4AchnZuRNhmo9",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/SOP_P_SPP_REVISI_.pdf",
                    },
                    {
                        tentang: "BIMBINGAN TEKNIS",
                        tahun: "2024",
                        link: "https://forms.gle/v6Vs4AchnZuRNhmo9",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Mekanisme_SPP_Orang_Asing_dan_Lembaga_Asing1.pdf",
                    },
                    {
                        tentang: "PEMBAGIAN 10 JUTA BENDERA",
                        tahun: "2024",
                        link: "https://forms.gle/v6Vs4AchnZuRNhmo9",
                        dokumen: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/SOP_PENERBITAN_SPP_TTD_DIR_ok.pdf",
                    },
                ]
            }
        }
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.items.push(response.data.data[i]);
        }
    },
    methods: {
    }
  }
  </script>