<template>
    <div class="wrapper-page">
        <div class="header">
            <div class="frame20">
                <div class="logo_placeholder">
                    <div class="logo">
                        <img src="../assets/logodagri.png" />
                        <img src="../assets/logopum.png" />
                    </div>
                    <div class="frame2">
                        <span style="width: 33.3vw; font-family: 'Open Sans'; font-size: 1.9vw; font-weight: 1000; text-align: left;">DIREKTORAT JENDERAL <br /> POLITIK DAN PEMERINTAHAN UMUM</span>
                    </div>
                </div>
                <div class="frame4">
                    <div class="frame3">
                        <!-- <a target="_blank" class="isikonten" style="cursor: pointer; padding: 5px 10px; background-color: #ffbb00; color: #ffffff; text-decoration: none; border-radius: 10px;" href=#>
                            <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                            Download Materi Kegiatan
                        </a> -->
                        <span style="font-family: 'Open Sans'; font-size: 16px; font-weight: 600; text-align: left;">{{ tanggal }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigasi">
            <ul class="section-nav">
                <li class="btn-1">
                    <router-link to="/" class="menu-parrent">
                        BERANDA
                    </router-link>
                </li>
                <li class="btn-1">
                    <div to="" class="menu-parrent" v-on:click="submenu(1)">
                        PROFIL
                        <font-awesome-icon v-if="showSubmenu === false && idTrue === 0 && idFalse === 0" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue === 1) && (idFalse === 1 || idFalse === 0)" :icon="['fas', 'chevron-up']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue !== 1) && (idFalse !== 1 || idFalse === 0)" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <ul v-if="showSubmenu === true && (idTrue === 1 || idTrue === 0) && (idFalse === 0 || idFalse === 1)" class="section-sub-nav">
                            <li class="btn-1">
                                <div v-on:click="goTo('sejarah')" class="menu-parrent" style="color: #002c55;">
                                    Sejarah
                                </div>
                            </li>
                            <li class="btn-1">
                                <div v-on:click="goTo('visi-misi')" class="menu-parrent" style="color: #002c55;">
                                    Visi & Misi
                                </div>
                            </li>
                            <li class="btn-1">
                                <div v-on:click="goTo('tugas-fungsi')" class="menu-parrent" style="color: #002c55;">
                                    Tugas & Fungsi
                                </div>
                            </li>
                            <li class="btn-1">
                                <div v-on:click="goTo('program-strategis')" class="menu-parrent" style="color: #002c55;">
                                    Program Strategis
                                </div>
                            </li>
                            <li class="btn-1">
                                <router-link to="/daftar-pejabat" class="menu-parrent" style="color: #002c55;">
                                    Daftar Pejabat
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/struktur" class="menu-parrent" style="color: #002c55;">
                                    Struktur Organisasi
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="btn-1">
                    <div to="" class="menu-parrent" v-on:click="submenu(2)">
                        INFORMASI PUBLIK
                        <font-awesome-icon v-if="showSubmenu === false && idTrue === 0 && idFalse === 0" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue === 2) && (idFalse === 2 || idFalse === 0)" :icon="['fas', 'chevron-up']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue !== 2) && (idFalse !== 2 || idFalse === 0)" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <ul v-if="showSubmenu === true && (idTrue === 2 || idTrue === 0) && (idFalse === 0 || idFalse === 2)" class="section-sub-nav">
                            <li class="btn-1">
                                <router-link to="/informasi-layanan" class="menu-parrent" style="color: #002c55;">
                                    Informasi wajib berkala
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/forum-gerakan" class="menu-parrent" style="color: #002c55;">
                                    Informasi tersedia setiap saat
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/daftar-ormas-skt" class="menu-parrent" style="color: #002c55;">
                                    Daftar informasi Publik
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <a target="_blank" href="https://ppid.kemendagri.go.id/front/member/permohonan/ajukan" class="menu-parrent" style="color: #002c55;">
                                    Permohonan Informasi Online
                                </a>
                            </li>
                            <!-- <li class="btn-1">
                                <router-link to="/informasi-layanan" class="menu-parrent" style="color: #002c55;">
                                    Informasi Layanan
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/forum-gerakan" class="menu-parrent" style="color: #002c55;">
                                    Forum & Gerakan
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/daftar-ormas-skt" class="menu-parrent" style="color: #002c55;">
                                    Daftar Ormas Ber-SKT
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="" class="menu-parrent" style="color: #002c55;">
                                    Info Pemilu
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="" class="menu-parrent" style="color: #002c55;">
                                    Infografis
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="" class="menu-parrent" style="color: #002c55;">
                                    Produk Hukum
                                </router-link>
                            </li> -->
                        </ul>
                    </div>
                </li>
                <li class="btn-1">
                    <div to="" class="menu-parrent" v-on:click="submenu(3)">
                        PUBLIKASI
                        <font-awesome-icon v-if="showSubmenu === false && idTrue === 0 && idFalse === 0" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue === 3) && (idFalse === 3 || idFalse === 0)" :icon="['fas', 'chevron-up']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue !== 3) && (idFalse !== 3 || idFalse === 0)" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <ul v-if="showSubmenu === true && (idTrue === 3 || idTrue === 0) && (idFalse === 0 || idFalse === 3)" class="section-sub-nav">
                            <li class="btn-1">
                                <router-link to="" class="menu-parrent" style="color: #002c55;">
                                    Berita
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="" class="menu-parrent" style="color: #002c55;">
                                    Artikel
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/daftar-kegiatan" class="menu-parrent" style="color: #002c55;">
                                    Daftar Kegiatan
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/rencana-kerja-anggaran" class="menu-parrent" style="color: #002c55;">
                                    Rencana Kerja & Anggaran
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/laporan-kinerja" class="menu-parrent" style="color: #002c55;">
                                    Laporan Kinerja
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="" class="menu-parrent" style="color: #002c55;">
                                    Reformasi Birokrasi
                                </router-link>
                            </li>
                            <li class="btn-1">
                                <router-link to="/prestasi-penghargaan" class="menu-parrent" style="color: #002c55;">
                                    Prestasi dan Penghargaan
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="btn-1">
                    <div to="" class="menu-parrent" v-on:click="submenu(4)">
                        PENGADUAN
                        <font-awesome-icon v-if="showSubmenu === false && idTrue === 0 && idFalse === 0" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue === 4) && (idFalse === 4 || idFalse === 0)" :icon="['fas', 'chevron-up']" style="color: #ffffff;" />
                        <font-awesome-icon v-else-if="showSubmenu === true && (idTrue === 0 || idTrue !== 4) && (idFalse !== 4 || idFalse === 0)" :icon="['fas', 'chevron-down']" style="color: #ffffff;" />
                        <ul v-if="showSubmenu === true && (idTrue === 4 || idTrue === 0) && (idFalse === 0 || idFalse === 4)" class="section-sub-nav">
                            <li class="btn-1">
                                <a href="https://ppid.kemendagri.go.id/" target="_blank" class="menu-parrent" style="color: #002c55;">
                                    Lapor PPID
                                </a>
                            </li>
                            <li class="btn-1">
                                <a href="https://kemendagri.lapor.go.id/" target="_blank" class="menu-parrent" style="color: #002c55;">
                                    Lapor.go.id
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  export default {
    name: 'HeaderWeb',
    data () {
        return {
            tanggal: "Tanggal Hari Ini",
            showSubmenu: false,
            idTrue: 0,
            idFalse: 0,
            menu: [
                {
                    id: 1,
                    parrent_id: 0,
                    nama_menu: 'BERANDA',
                    path: '/',
                    link: '',
                    children_menu: []
                },
                {
                    id: 2,
                    parrent_id: 0,
                    nama_menu: 'INFORMASI WAJIB BERKALA',
                    path: '',
                    link: '',
                    children_menu: [
                        {
                            id: 3,
                            parrent_id: 2,
                            nama_menu: 'SEJARAH',
                            path: '',
                            link: '',
                        },
                    ]
                },
                {
                    id: 3,
                    parrent_id: 0,
                    nama_menu: 'INFORMASI TERSEDIA SETIAP SAAT',
                    path: '',
                    children_menu: [
                        {
                            id: 3,
                            parrent_id: 2,
                            nama_menu: '',
                            path: '',
                            link: '',
                        },
                    ]
                },
                {
                    id: 4,
                    parrent_id: 0,
                    nama_menu: 'DAFTAR INFORMASI PUBLIK',
                    path: '',
                    link: '',
                    children_menu: [
                        {
                            id: 3,
                            parrent_id: 2,
                            nama_menu: '',
                            path: '',
                            link: '',
                        },
                    ]
                },
                {
                    id: 5,
                    parrent_id: 0,
                    nama_menu: 'PERMOHONAN INFORMASI ONLINE',
                    path: '',
                    link: 'https://ppid.kemendagri.go.id/front/member/permohonan/ajukan',
                    children_menu: []
                },
            ],
        };
    },
    mounted () {
        this.tanggal = moment().format('dddd, DD MMMM YYYY');
    },
    methods: {
        submenu (id) {
            if (this.showSubmenu === false && this.idTrue === 0 && this.idFalse === 0) {
                this.showSubmenu = true
                this.idTrue = id
            } else if (this.showSubmenu === true && this.idTrue === id && this.idFalse === 0) {
                this.showSubmenu = false
                this.idTrue = 0
            } else if (this.showSubmenu === true && this.idTrue !== id && this.idFalse === 0) {
                this.showSubmenu = true
                this.idFalse = id
                this.idTrue = 0
            } else if (this.showSubmenu === true && this.idTrue === 0 && this.idFalse === id) {
                this.showSubmenu = false
                this.idFalse = 0
            } else if (this.showSubmenu === true && this.idTrue === 0 && this.idFalse !== id) {
                this.showSubmenu = true
                this.idFalse = id
                this.idTrue = 0
            }
        },
        goTo (par) {
            this.$router.push({ name: 'pageredirect', params: { id: par } })
        }
    }
  }
  </script>
  
  