<template>
    <div class="wrapper-page">
      <div class="section" style="height: 450px;">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">PRESTASI DAN PENGHARGAAN</span>
            </div>
            <div class="frame10">
                <div class="rencanakerja">
                    
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'AgendaKegiatanWeb',
    components: {
    },
    data () {
      return {
        headers:[
          { text: "Judul Rencana Kerja", value: "judul_rencana_kerja", width: "width: 375px; padding: 5px 5px; text-align: center;" },
          { text: "Tahun", value: "tahun", sortable: true, width: "width: 100px; padding: 5px 5px; text-align: center;"  },
          { text: "Dokumen", value: "dookumen", sortable: true, width: "width: 150px; padding: 5px 5px; text-align: center;"  },
          { text: "Dukungan", value: "dukungan", sortable: true, width: "width: 250px; padding: 5px 5px; text-align: center;"  }
        ],
        items: []
      }
    },
    mounted () {
        const response = {
            data: {
                data: [
                    {
                        judul_rencana_kerja: "Laporan Kinerja Ditjen Politik dan Pemerintahan Umum",
                        tahun: '2020-2024',
                        dokumen: "https://polpum.kemendagri.go.id/wp-content/uploads/2019/07/001-Renstra-Ditjen-Polpum-1.pdf",
                        dukungan: "https://polpum.kemendagri.go.id/wp-content/uploads/2019/07/renja-2023-polpum-1.pdf",
                    },
                ]
            }
        }
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.items.push(response.data.data[i]);
        }
    },
    methods: {
    }
  }
  </script>