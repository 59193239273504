<template>
    <div class="wrapper-page">
      <div class="section">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">STRUKTUR ORGANISASI</span>
            </div>
            <div class="framestruktur">
                <!-- <PDFViewer source="../assets/SOTK.pdf" style="height: 100vh; width: 69.44vw;" download="https://polpum.kemendagri.go.id/wp-content/uploads/2023/07/SOTK.pdf" /> -->
                <div class="section-detail-pejabat">
                    <a target="_blank" class="isikonten" style="cursor: pointer; padding: 5px 10px; background-color: #ffbb00; color: #ffffff; text-decoration: none; border-radius: 10px;" href="https://polpum.kemendagri.go.id/wp-content/uploads/2023/07/Unit-Kerja.pdf">
                        <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #ffffff;" />
                        Lihat Unit Kerja
                    </a>
                    <a target="_blank" class="isikonten" style="cursor: pointer; padding: 5px 10px; background-color: #ffbb00; color: #ffffff; text-decoration: none; border-radius: 10px;" href="https://polpum.kemendagri.go.id/wp-content/uploads/2023/07/SOTK.pdf">
                        <font-awesome-icon :icon="['fas', 'file-arrow-down']" style="color: #ffffff;" />
                        Download Struktur
                    </a>
                </div>
                <div class="section-struktur">
                    <img style="width: 69.44vw;" src="../assets/struktur_polpum.png" />
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
//   import PDFViewer from 'pdf-viewer-vue'
  
  export default {
    name: 'StrukturWeb',
    components: {
        // PDFViewer,
    },
    data () {
      return {
        // urlpdfstruktur: '../',
      }
    },
    mounted () {
    },
    methods: {
    }
  }
  </script>