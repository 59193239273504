<template>
    <div class="footer">
        <div class="frame83">
            <div class="frame76">
                <img src="../assets/logodagri.png" style="width: 42px; height: 55px;" />
                <div class="frame77">
                    <span style="font-family: 'Open Sans'; font-size: 10px; font-weight: 600; text-align: left; color: #ffffff;">
                        DIREKTORAT JENDERAL <br/>
                        POLITIK DAN PEMERINTAHAN UMUM</span>
                    <span style="font-family: 'Open Sans'; font-size: 8px; font-weight: 600; text-align: left; color: #ffffff;">
                        KEMENTERIAN DALAM NEGEERI <br/>
                        REPUBLIK INDONESIA</span>
                </div>
            </div>
            <div class="frame78">
                <div class="iconmedsos">
                    <font-awesome-icon :icon="['fab', 'facebook']" style="color: #ffffff;" />
                </div>
                <div class="iconmedsos">
                    <font-awesome-icon :icon="['fab', 'instagram']" style="color: #ffffff;" />
                </div>
                <div class="iconmedsos">
                    <font-awesome-icon :icon="['fab', 'twitter']" style="color: #ffffff;" />
                </div>
                <div class="iconmedsos">
                    <font-awesome-icon :icon="['fab', 'youtube']" style="color: #ffffff;" />
                </div>
            </div>
        </div>
        <div class="frame84"></div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'FooterWeb',
    data () {
        return {
        };
    },
    mounted () {
    },
  }
  </script>
  
  