<template>
    <div class="wrapper-page">
      <div class="section" style="height: 450px;">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">FORUM & GERAKAN</span>
            </div>
            <div class="frame10">
                <div class="rencanakerja">
                    <table class="isikonten" style="gap: 10px; display: flex; flex-direction: column;">
                        <thead style="border-bottom: 3px solid #002c5573;">
                            <tr>
                                <template v-for="(item, index) in headers" v-bind:key="index">
                                    <th :style="item.width">{{ item.text }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody style="display: flex; gap: 5px; flex-direction: column; justify-content: center; align-items: center;">
                            <template v-for="(itemm, indexx) in items" v-bind:key="indexx">
                                <tr style="border-bottom: 1px solid #002c5573;">
                                    <td style="padding: 5px 5px; width: 450px; font-size: 16px; text-align: left;">{{ itemm.nama_forum_gerakan }}</td>
                                    <td style="padding: 5px 5px; width: 100px; font-size: 16px; text-align: center;">{{ itemm.singkatan }}</td>
                                    <td style="padding: 5px 5px; width: 175px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.dasar_hukum" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #ffffff;" />
                                            Lihat Dasar Hukum
                                        </a>
                                    </td>
                                    <td style="padding: 5px 5px; width: 150px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.rekap" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #ffffff;" />
                                            Lihat Rekap
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'ForumGerakanWeb',
    components: {
    },
    data () {
      return {
        headers:[
          { text: "Nama Forum/Gerakan", value: "nama_forum_gerakan", width: "width: 450px; padding: 5px 5px; text-align: center;" },
          { text: "Singkatan", value: "singkatan", width: "width: 100px; padding: 5px 5px; text-align: center;" },
          { text: "Dasar Hukum", value: "dasar_hukum", sortable: true, width: "width: 175px; padding: 5px 5px; text-align: center;"  },
          { text: "Rekap", value: "rekap", sortable: true, width: "width: 150px; padding: 5px 5px; text-align: center;"  }
        ],
        items: []
      }
    },
    mounted () {
        const response = {
            data: {
                data: [
                    {
                        nama_forum_gerakan: "Forum Kewaspadaan Dini Masyarakat",
                        singkatan: "FKDM",
                        dasar_hukum: "",
                        rekap: ""
                    },
                    {
                        nama_forum_gerakan: "Forum Kerukunan Umat Beragama",
                        singkatan: "FKUB",
                        dasar_hukum: "",
                        rekap: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Rekapitulasi_Pembentukan_FKUB_2015.pdf"
                    },
                    {
                        nama_forum_gerakan: "Forum Pembauran Kebangsaan",
                        singkatan: "FPK",
                        dasar_hukum: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Permen_no.34-2006_FPK_1.pdf",
                        rekap: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/Database_FPK_2015.pdf"
                    },
                    {
                        nama_forum_gerakan: "Pusat Pendidikan Wawasan Kebangsaan",
                        singkatan: "PPWK",
                        dasar_hukum: "https://polpum.kemendagri.go.id/wp-content/uploads/2019/04/Grafik-dan-Daftar-Pembentukan-PPWK-18-Oktober-2022.pdf",
                        rekap: "https://polpum.kemendagri.go.id//wp-content/uploads/2019/03/PPWK_Tahun_2015.pdf"
                    },
                    {
                        nama_forum_gerakan: "Gerakan Nasional Revolusi Mental",
                        singkatan: "GNRM",
                        dasar_hukum: "https://polpum.kemendagri.go.id/wp-content/uploads/2023/06/Inpres-Nomor-12-Tahun-2016-1.pdf",
                        rekap: "https://polpum.kemendagri.go.id/wp-content/uploads/2023/06/Grafik-dan-Daftar-Pembentukan-GNRM-22-Mei-2023.pdf"
                    },
                ]
            }
        }
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.items.push(response.data.data[i]);
        }
    },
    methods: {
    }
  }
  </script>