<template>
    <div class="wrapper-page">
    </div>
  </template>
 <script>
  
  export default {
    name: 'PageRedirectWeb',
    components: {},
    data () {
      return {
        halaman_ini: this.$route.params.id,
      }
    },
    mounted () {
        this.$router.push({ name: 'page', params: { id: this.halaman_ini } })
    },
    methods: {
    }
  }
  </script>