<template>
    <div class="wrapper-page">
      <div class="section">
        <div class="frame">
          <div class="frame9">
            <span class="judulkonten">LAPORAN KINERJA</span>
          </div>
          <div class="frame10">
            <div class="laporankinerja">
              <div class="section-laporan-kinerja" :id="index" v-for="(item, index) in laporanKinerja" :key="index">
                <a :href="item.link" class="isilaporankinerja">
                    <div class="fotocoverlaporankinerja">
                        <img style="width: 230px;" :src="item.cover" />
                    </div>
                    <div class="inforlaporankinerja">
                        <span class="isikonten" style="font-size: 15px; font-weight: 1000; text-align: center;">{{ item.judul_laporan }}</span>
                        <span class="isikonten" style="font-size: 14px; font-weight: 1000; text-align: center;">Tahun {{ item.tahun }}</span>
                        <span class="isikonten" style="font-size: 12px; font-weight: 1000; text-align: center;">
                            <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #012b53;" />
                            {{ item.viewer_laporan }} 
                            Pengguna
                        </span>
                    </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'LaporanKinerjaWeb',
    components: {},
    data () {
      return {
        laporanKinerja: []
      }
    },
    mounted () {
        const response = {
            data: {
                data: [
                    {
                        judul_laporan: "Laporan Kinerja Ditjen Politik dan Pemerintahan Umum",
                        tahun: 2020,
                        link: "https://polpum.kemendagri.go.id/wp-content/uploads/2021/04/LAPKIN_POLPUM-2020__compressed.pdf",
                        cover: "http://localhost:8080/img/laporan2020.a24a1944.png",
                        viewer_laporan: 0
                    },
                    {
                        judul_laporan: "Laporan Kinerja Ditjen Politik dan Pemerintahan Umum",
                        tahun: 2021,
                        link: "https://polpum.kemendagri.go.id/wp-content/uploads/2023/07/combinepdf-1_compressed.pdf",
                        cover: "http://localhost:8080/img/laporan2021.1df71dda.png",
                        viewer_laporan: 0
                    },
                    {
                        judul_laporan: "Laporan Kinerja Ditjen Politik dan Pemerintahan Umum",
                        tahun: 2022,
                        link: "https://polpum.kemendagri.go.id/wp-content/uploads/2023/07/Laporan-Kinerja-Direktorat-Jenderal-Polpum-2022.pdf",
                        cover: "http://localhost:8080/img/laporan2022.37f1fb13.png",
                        viewer_laporan: 0
                    },
                ]
            }
        }
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.laporanKinerja.push(response.data.data[i]);
        }
    },
    methods: {
    }
  }
  </script>