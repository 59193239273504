<template>
  <div class="wrapper-page">
    <HeaderWeb style="z-index: 1;"/>
    <router-view/>
    <FooterWeb/>
    <div v-if="popuplogin" id="popuplogin" class="logincss" style="width: 100%; height: 100%; left: calc(50% - 100%/2) !important; top: calc(50% - 100%/2) !important; display: flex; align-items: center; justify-content: center;">
      <div v-on:click="closepopuplogin()" style="position: absolute; width: 100%; height: 100%; left: calc(0%) !important; top: calc(0%) !important;"></div>
      <div class="login" style="z-index: 1; position: relative; background: white; overflow: scroll;max-height: 80%; padding: 4vw;">
        <div v-if="themedark===true" class="login_kiri loginbackgroundkiri">
          <div class="lotties">
            <Vue3Lottie :animationData="logins" :width="200" />
          </div>
        </div>
        <div v-if="themeligth===true" class="login_kiri" style="background: #f7f7f9;">
          <div class="lotties">
            <Vue3Lottie :animationData="logins" :width="200" />
          </div>
        </div>
        <div v-if="themedark===true" class="login_kanan loginbackgroundkanan">
          <div class="tombolgantitema">
            <button v-if="themedark===true" v-on:click="ubahtema(themeligth, themedark)" class="csstombolgantitemadark" style="color: #EAEAFF99;">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--dark iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24" style="font-size: 24px; height: 24px; width: 24px;"><path fill="currentColor" d="m17.75 4.09l-2.53 1.94l.91 3.06l-2.63-1.81l-2.63 1.81l.91-3.06l-2.53-1.94L12.44 4l1.06-3l1.06 3zm3.5 6.91l-1.64 1.25l.59 1.98l-1.7-1.17l-1.7 1.17l.59-1.98L15.75 11l2.06-.05L18.5 9l.69 1.95zm-2.28 4.95c.83-.08 1.72 1.1 1.19 1.85c-.32.45-.66.87-1.08 1.27C15.17 23 8.84 23 4.94 19.07c-3.91-3.9-3.91-10.24 0-14.14c.4-.4.82-.76 1.27-1.08c.75-.53 1.93.36 1.85 1.19c-.27 2.86.69 5.83 2.89 8.02a9.96 9.96 0 0 0 8.02 2.89m-1.64 2.02a12.08 12.08 0 0 1-7.8-3.47c-2.17-2.19-3.33-5-3.49-7.82c-2.81 3.14-2.7 7.96.31 10.98c3.02 3.01 7.84 3.12 10.98.31"></path></svg>
              <span class="tooltiptextdark">Dark</span>
            </button>
          </div>
          <div class="spasis" style="display: flex; justify-content: center; align-items: center; width: fit-content; gap: 1vw">
            <div class="logo" style="align-items: center;">
              <img src="./assets/logodagri.png"  style="height: 5vw;"/>
              <img src="./assets/logopum.png"  style="width: 5vw;"/>
            </div>
            <div class="frame2">
              <span style="font-family: 'Open Sans'; font-size: 1.2vw; font-weight: 1000; text-align: left; color: #f7f7f9;">DIREKTORAT JENDERAL <br /> POLITIK DAN PEMERINTAHAN UMUM</span>
            </div>
          </div>
          <div class="spasis" style="padding: 32px 32px; width: 100%; display: flex; justify-content: center; align-items: center;">
            <span class="fontloginselamatdatang" style="color: #d2d3e8; text-align: center;">Selamat Datang di CMS<br/>Website Ditjen Polpum</span>
          </div>
          <div style="display: flex; flex-direction: column; gap: 1vw;">
            <div style="display: flex; flex-direction: row;">
              <span style="display: flex; width: 7vw;">Username : </span>
              <input type="text"/>
            </div>
            <div style="display: flex; flex-direction: row;">
              <span style="display: flex; width: 7vw;">Password : </span>
              <input type="password"/>
            </div>
          </div>
          <div class="lupapassword">
        
            <div style="color: #666cff">
              Lupa Password?
            </div>
          </div>
          <div class="tombollogin">
            <button class="buttonlogin" v-on:click="logintombol(username, password)">
              LOGIN
            </button>
          </div>
        </div>
        <div v-if="themeligth===true" class="login_kanan">
          <div class="tombolgantitema">
            <button v-if="themeligth===true" v-on:click="ubahtema(themeligth, themedark)" class="csstombolgantitemalight" style="color: #4C4E6499;">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" tag="i" class="v-icon notranslate v-theme--light iconify iconify--mdi" width="1em" height="1em" viewBox="0 0 24 24" style="font-size: 24px; height: 24px; width: 24px;"><path fill="currentColor" d="M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0-7l2.39 3.42C13.65 5.15 12.84 5 12 5s-1.65.15-2.39.42zM3.34 7l4.16-.35A7.2 7.2 0 0 0 5.94 8.5c-.44.74-.69 1.5-.83 2.29zm.02 10l1.76-3.77a7.13 7.13 0 0 0 2.38 4.14zM20.65 7l-1.77 3.79a7.02 7.02 0 0 0-2.38-4.15zm-.01 10l-4.14.36c.59-.51 1.12-1.14 1.54-1.86c.42-.73.69-1.5.83-2.29zM12 22l-2.41-3.44c.74.27 1.55.44 2.41.44c.82 0 1.63-.17 2.37-.44z"></path></svg>
              <span class="tooltiptextlight">Light</span>
            </button>
          </div>
          <div class="spasis" style="display: flex; justify-content: center; align-items: center; width: fit-content; gap: 1vw">
            <div class="logo" style="align-items: center;">
              <img src="./assets/logodagri.png"  style="height: 5vw;"/>
              <img src="./assets/logopum.png"  style="width: 5vw;"/>
            </div>
            <div class="frame2">
              <span style="font-family: 'Open Sans'; font-size: 1.2vw; font-weight: 1000; text-align: left;">DIREKTORAT JENDERAL <br /> POLITIK DAN PEMERINTAHAN UMUM</span>
            </div>
          </div>
          <div class="spasis" style="padding: 32px 32px; width: 100%; display: flex; justify-content: center; align-items: center;">
            <span class="fontloginselamatdatang" style="color: #636579; text-align: center;">Selamat Datang di CMS<br/>Website Ditjen Polpum</span>
          </div>
          <div style="display: flex; flex-direction: column; gap: 1vw;">
            <div style="display: flex; flex-direction: row;">
              <span style="display: flex; width: 7vw;">Username : </span>
              <input type="text"/>
            </div>
            <div style="display: flex; flex-direction: row;">
              <span style="display: flex; width: 7vw;">Password : </span>
              <input type="password"/>
            </div>
          </div>
          <div class="lupapassword">
            
            <div style="color: #666cff">
              Lupa Password?
            </div>
          </div>
          <div class="tombollogin">
            <button class="buttonlogin" v-on:click="logintombol(username, password)">
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderWeb from '@/components/Header.vue'
import FooterWeb from '@/components/Footer.vue'
import axios from 'axios'
import { Vue3Lottie } from "vue3-lottie";
import logins from "../src/assets/login.json";
import Swal from 'sweetalert2'
export default {
  name: 'HomeView',
  components: {
    HeaderWeb,
    FooterWeb,
    Vue3Lottie,
  },
  data() {
    return {
      popuplogin: false,
      logins,
      themeligth: false,
      themedark: false,
      themesystem: true,
      visibelpass: false,
      username: '',
      password: '',
      message: '',
      access_token: '',
      refresh_token: '',
      menu: [],
      touchlama: false,
      touchtime: 0,
    };
  },
  mounted() {
    
  },
  methods: {
    onTouchstart(){
      if(this.popuplogin===false){
        this.touchtime = setTimeout(this.logintouch, 1000)
      }
    },
    onTouchend(){
      clearTimeout(this.touchtime)
    },
    logintouch(){
      var darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)")
      var lightdarkThemeMq = window.matchMedia("(prefers-color-scheme: light)")
      if(darkThemeMq.matches===true && lightdarkThemeMq.matches===false){
        this.themeligth = false
        this.themedark = true
      }
      if(darkThemeMq.matches===false && lightdarkThemeMq.matches===true){
        this.themeligth = true
        this.themedark = false
      }
          if (localStorage.getItem('token')) {
            window.location.href = this.base_url_cms + "/apps/dashboard/dashboardweb";
          } else {
            this.popuplogin = true
          }
      },
      login(event){
        console.log(event)
        var darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)")
        var lightdarkThemeMq = window.matchMedia("(prefers-color-scheme: light)")
        if(darkThemeMq.matches===true && lightdarkThemeMq.matches===false){
          this.themeligth = false
          this.themedark = true
        }
        if(darkThemeMq.matches===false && lightdarkThemeMq.matches===true){
          this.themeligth = true
          this.themedark = false
        }
        if(event.key==="q"){
          if (localStorage.getItem('token')) {
            window.location.href = this.base_url_cms + "/apps/dashboard/dashboardweb";
          } else {
            this.popuplogin = true
          }
        }
      },
      logintombol(username, password){
        if((username===''||password==='') && !localStorage.getItem('token')){
          Swal.fire({
            // title: 'Error!',
            text: 'Silahkan masukan username dan password Anda!',
            // icon: 'error',
            confirmButtonText: 'Ok'
          })
          // alert(`Silahkan masukan username dan password Anda!`)
        } 
        if (localStorage.getItem('token')) {
          window.location.href = this.base_url_cms + "/apps/dashboard/dashboardweb";
        } else {
          axios
          .post(this.base_url + 'api/login', {
              username: username,
              password:  password,
          })
          .then((logins) => {
            if(logins.data.message!=='success'){
              Swal.fire({
                // title: 'Error!',
                text: 'Username atau Password salah!',
                // icon: 'error',
                confirmButtonText: 'Ok'
              })
            }else{
              localStorage.setItem("token", logins.data.access_token);
              window.location.href = this.base_url_cms + "/passing?data=" + logins.data.access_token;
            }
          })
        }
        
      },
      ubahtema(light, dark){
        if(light === true && dark === false){
          this.themeligth = false
          this.themedark = true
        }
        if(light === false && dark === true){
          this.themeligth = true
          this.themedark = false
        }
      },
      closepopuplogin(){
        this.popuplogin = false
      },
      visibelpassword(visibelpass){
        if(visibelpass===false){
          this.visibelpass = true
        }else{
          this.visibelpass = false
        }
      },
  },
  created: function () {
    window.addEventListener("keydown", this.login)
    window.addEventListener("touchstart", this.onTouchstart)
    window.addEventListener("touchend", this.onTouchend)
  },
}
</script>
<style>

</style>
