<template>
    <div class="wrapper-page">
      <div class="section" style="height: 450px;">
        <div class="frame">
            <div class="frame9">
                <span class="judulkonten">DAFTAR ORMAS BER-SKT</span>
            </div>
            <div class="frame10">
                <div class="rencanakerja">
                    <table class="isikonten" style="gap: 10px; display: flex; flex-direction: column;">
                        <thead style="border-bottom: 3px solid #002c5573;">
                            <tr>
                                <template v-for="(item, index) in headers" v-bind:key="index">
                                    <th :style="item.width">{{ item.text }}</th>
                                </template>
                            </tr>
                        </thead>
                        <tbody style="display: flex; gap: 5px; flex-direction: column; justify-content: center; align-items: center;">
                            <template v-for="(itemm, indexx) in items" v-bind:key="indexx">
                                <tr style="border-bottom: 1px solid #002c5573;">
                                    <td style="padding: 5px 5px; width: 450px; font-size: 16px; text-align: left;">{{ itemm.nama_forum_gerakan }}</td>
                                    <td style="padding: 5px 5px; width: 100px; font-size: 16px; text-align: center;">{{ itemm.singkatan }}</td>
                                    <td style="padding: 5px 5px; width: 175px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.dasar_hukum" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #ffffff;" />
                                            Lihat Dasar Hukum
                                        </a>
                                    </td>
                                    <td style="padding: 5px 5px; width: 150px; font-size: 13px; text-align: center;">
                                        <a target="_blank" :href="itemm.rekap" style="text-decoration: none; background-color: #002c55; padding: 5px 10px; border-radius: 5px; color: #ffffff;">
                                            <font-awesome-icon :icon="['fas', 'binoculars']" style="color: #ffffff;" />
                                            Lihat Rekap
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'DaftarOrmsSKTWeb',
    components: {
    },
    data () {
      return {
        headers:[
          { text: "Nama Forum/Gerakan", value: "nama_forum_gerakan", width: "width: 450px; padding: 5px 5px; text-align: center;" },
          { text: "Singkatan", value: "singkatan", width: "width: 100px; padding: 5px 5px; text-align: center;" },
          { text: "Dasar Hukum", value: "dasar_hukum", sortable: true, width: "width: 175px; padding: 5px 5px; text-align: center;"  },
          { text: "Rekap", value: "rekap", sortable: true, width: "width: 150px; padding: 5px 5px; text-align: center;"  }
        ],
        items: []
      }
    },
    mounted () {
        axios.get('https://api.siormas.kemendagri.go.id/pencarian-data-ormas?filter=%7B%22offset%22%3A0%2C%22limit%22%3A10%2C%22skip%22%3A0%2C%22order%22%3A%22id%22%2C%22where%22%3A%7B%22nama_ormas%22%3A%7B%22ilike%22%3A%22%25%25%22%7D%7D%2C%22include%22%3A%5B%7B%22relation%22%3A%22bidangKegiatans%22%2C%22scope%22%3A%7B%22include%22%3A%5B%7B%22relation%22%3A%22masterJenisOrmas%22%7D%5D%7D%7D%2C%7B%22relation%22%3A%22masterLokasiSekretariat%22%7D%5D%7D')
      .then((response)=>{
        // handle success
        var i=0;
        for(i=0; i<response.data.data.length; i++){
            this.items.push(response.data.data[i])
        }
        console.log(this.items)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
    },
    methods: {
    }
  }
  </script>